.restartbar {
    background-color: #ffffb2;
    border-left: 5px solid #ffc646;
    box-shadow: 0 7px 30px 0 rgba(0, 0, 0, 0.7);
    cursor: pointer;
    display: flex;
    left: 40px;
    margin-top: -10px;
    margin-bottom: 10px;
    padding: 10px;
    opacity: 0;
    position: absolute;
    top: 40px;
    transition: background-color 0.1s ease-in-out, opacity 0.3s ease-in-out;
    visibility: hidden;
}

.restartbar:hover {
    background-color: #E6E699;
}

.restartbar-visible {
    opacity: 1;
    visibility: visible;
}

.restartbar-warning {
    background-color: #ffb3b3;
    border-left: 5px solid #ff2b2b;
}

.restartbar-warning:hover {
    background-color: #ff8080;
}

.restartbar-text {
    color: #364040;
    margin-left: 10px;
}

.restartbar-title {
    font-weight: bold;
}

.restartbar-description {
    font-weight: normal;
}
